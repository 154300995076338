import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormDataService } from "../data/form-data.service";

export enum TAB_NAMES {
  CALENDAR = "calendar",
  TYPE = "type",
  TICKETS = "tickets",
  PAYMENT = "payment",
}

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent implements OnInit, OnDestroy {
  activeSteps: any;
  isOpenTicket: boolean = false;
  public tabNames = TAB_NAMES;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formDataService: FormDataService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        this.isOpenTicket = !!params.open_ticket;
      });
    this.activeSteps = this.formDataService.getActiveStep();
  }

  navigateToTab(tabName: TAB_NAMES) {
    this.router.navigate([`/${tabName}`], { queryParamsHandling: "merge" });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
