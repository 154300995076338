import { Injectable } from "@angular/core";
import { FormDataService } from "../data/form-data.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class StepTwoGuardService {
  constructor(private formData: FormDataService, public router: Router) {}

  canActivate(): boolean {
    if (!this.formData.activeSteps["step_2"]) {
      this.router.navigate(["/calendar"], { queryParamsHandling: "merge" });
      return false;
    }
    return true;
  }
}
