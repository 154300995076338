import { Injectable } from "@angular/core";
import { CONFIG } from "./config/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { FormDataService } from "./data/form-data.service";
import { NgxSmartModalService } from "ngx-smart-modal";

@Injectable({
  providedIn: "root",
})
export class ApiDataService {
  locationURL: string = "";

  private headers: HttpHeaders;

  private modalObj: Object = {
    title: "Si è verificato un errore!",
    icon: "sad.png", // => assets/img/icons/ {name}
    btnText: "Indietro",
    btnRedirect: "/",
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private formDataService: FormDataService,
    public ngxSmartModalService: NgxSmartModalService
  ) {
    this.headers = new HttpHeaders().set("Content-Type", "application/json");
  }

  getCalendar(data): Observable<any> {
    data.api_version = 'v2';
    this.locationURL = this.formDataService.getLocationUrl().permalink;
    return this.http
      .post(
        CONFIG.API_URL + "locations/" + this.locationURL + "/search/calendar",
        data,
        { headers: this.headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.modalObj["title"] = error.error.errors[0];
          }

          this.ngxSmartModalService.setModalData(
            this.modalObj,
            "kalataModalInfo",
            true
          );

          this.ngxSmartModalService.open("kalataModalInfo");

          return of(result as T);
        })
      );
  }

  getCountries(): Observable<any> {
    return this.http.get(CONFIG.API_URL + "countries").pipe(
      map((res: any) => res.countries),
      catchError(<T>(error: any, result?: T) => {
        return of(result as T);
      })
    );
  }

  getCourses(req: string = null): Observable<any> {
    let data = {
      date: req,
      "api_version": "v2"
    };
    this.locationURL = this.formDataService.getLocationUrl().permalink;

    return this.http
      .post(
        CONFIG.API_URL + "locations/" + this.locationURL + "/search/courses",
        data,
        { headers: this.headers }
      )
      .pipe(
        map((res: any) => res),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.modalObj["title"] = error.error.errors[0];
          }

          this.ngxSmartModalService.setModalData(
            this.modalObj,
            "kalataModalInfo",
            true
          );

          this.ngxSmartModalService.open("kalataModalInfo");

          return of(result as T);
        })
      );
  }

  getTickets(req): Observable<any> {
    let data = {
      date: req.date,
      course_id: req.course ? req.course.id : null,
      api_version: "v2"
    };
    this.locationURL = this.formDataService.getLocationUrl().permalink;

    return this.http
      .post(
        CONFIG.API_URL + "locations/" + this.locationURL + "/search/tickets",
        data,
        { headers: this.headers }
      )
      .pipe(
        map((res: any) => res),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.modalObj["title"] = error.error.errors[0];
          }

          this.ngxSmartModalService.setModalData(
            this.modalObj,
            "kalataModalInfo",
            true
          );

          this.ngxSmartModalService.open("kalataModalInfo");

          return of(result as T);
        })
      );
  }

  applyDiscount(data: {
    discount_code?: string;
    location_permalink: string;
    tickets: Array<{ id: string; quantity: number }>;
  }): Observable<any> {
    let url = `${CONFIG.API_URL}cart/cost`;
    return this.http.post(url, data, { headers: this.headers });
  }

  buyTickets(
    slots,
    user,
    isOpenTicket: boolean = false,
    discount_code?: string,
    date?: string,
    course_id?: string
  ): Observable<any> {
    let data: any = {
      user_name: user.user_name,
      user_surname: user.user_surname,
      user_email: user.user_email,
      user_phone: user.user_phone,
      country_id: user.country_id,
      user_zip: user.user_zip,
      location_permalink: this.locationURL,
      api_version: "v2",
      date: date,
      course_id: course_id
    };
    
    //if (isOpenTicket) data.course = slots[0];
    if(isOpenTicket) data.tickets = slots[0].tickets
    else data.time_slots = slots;

    if (discount_code) data.discount_code = discount_code;

    return this.http
      .post(CONFIG.API_URL + "cart/checkout", data, { headers: this.headers })
      .pipe(
        map((res: any) => {
          return {
            success: true,
            data: res,
          };
        }),
        catchError(<T>(error: any, result?: T) => {
          if (error.error.errors) {
            this.modalObj["title"] = error.error.errors[0];
          }

          this.ngxSmartModalService.setModalData(
            this.modalObj,
            "kalataModalInfo",
            true
          );

          this.ngxSmartModalService.open("kalataModalInfo");

          return of(result as T);
        })
      );
  }
}
