import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { NgxSmartModalComponent, NgxSmartModalService } from "ngx-smart-modal";
import { Router, NavigationEnd } from "@angular/router";
import { FormDataService } from "./data/form-data.service";
declare let fbq: Function;
import { locale as itLang } from './config/i18n/it';
import { TranslationService } from "./config/translation.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  locations: any;
  modelSelection: any;

  constructor(
    private formDataService: FormDataService,
    public ngxSmartModalService: NgxSmartModalService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private translationService: TranslationService,
  ) {
    router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        fbq("track", "PageView");
      }
    });

    // register translations
		this.translationService.loadTranslations(itLang);
  }

  ngOnInit() {}

  selectedLocation(form) {
    if (!form.invalid) {
      this.formDataService.setLocationUrl({
        permalink: this.modelSelection,
        name: "Foo",
      });
      this.ngxSmartModalService.getModal("kalataModalInfo").close();
    }
  }

  ngAfterViewInit() {
    this.ngxSmartModalService
      .getModal("kalataModalInfo")
      .onOpen.subscribe((modal: NgxSmartModalComponent) => {});

    this.ngxSmartModalService
      .getModal("kalataModalInfo")
      .onAnyCloseEvent.subscribe((modal: NgxSmartModalComponent) => {
        this.modalRedirect(modal.getData().btnRedirect);
      });

    // Needed to avoid "Expression has changed since the last check cycle..."
    this.cdr.detectChanges();
  }

  private modalRedirect(route: string) {
    this.router.navigate([route], { queryParamsHandling: "merge" });
    return;
  }
}
