import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormDataService } from "../data/form-data.service";
import { SharedDataService } from "../config/shared-data.service";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  locationName: any;
  isOpenTicket: boolean = false;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formDataService: FormDataService,
    private sharedDataService: SharedDataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.sharedDataService.currentMessage
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => (this.locationName = data));
    this.route.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        this.isOpenTicket = !!params.open_ticket;
        if (params.location) {
          this.sharedDataService
            .getLocations()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
              (res) =>{
              (this.locationName =
                res.data.locations[
                  res.data.locations.findIndex(
                    (location) => location.permalink === params.location
                  )
                ].name)
              }
            );
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  checkUrl() {
    return window.location.pathname != '/locations';
  }
}
