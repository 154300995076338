import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormDataService } from "../data/form-data.service";
import { SharedDataService } from "../config/shared-data.service";
import { ApiDataService } from "../api-data.service";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
declare let fbq: Function;
@Component({
  selector: "app-tab-tickets",
  templateUrl: "./tab-tickets.component.html",
  styleUrls: ["./tab-tickets.component.scss"],
})
export class TabTicketsComponent implements OnInit, OnDestroy {
  locationName: string;
  locationPermalink: string;
  isOpenTicket: boolean = false;
  public discount: string = "";
  public discountResponse: any;
  public discountError: boolean = false;

  isLoading: boolean = true;

  apiData: any = { date: "", course: "" };

  ticketsArrModified: Array<any> = [];

  cartItems: any;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formDataService: FormDataService,
    private sharedService: SharedDataService,
    public apiService: ApiDataService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // router.events.subscribe((y: NavigationEnd) => {
    //   if (y instanceof NavigationEnd) {
    //     fbq("track", "InitiateCheckout");
    //   }
    // });
  }

  ngOnInit() {
    this.sharedService.currentMessage
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => (this.locationName = data));

    this.locationPermalink = this.formDataService.getLocationUrl()["permalink"];

    this.route.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        this.isOpenTicket = !!params.open_ticket;
        if (params.location) {
          this.locationPermalink = params.location;
          this.sharedService
            .getLocations()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
              (res) =>
                (this.locationName =
                  res.data.locations[
                    res.data.locations.findIndex(
                      (location) => location.permalink === params.location
                    )
                  ].name)
            );
        }
      });

    this.apiData = {
      date: !this.isOpenTicket
        ? this.formDataService.getSelectedDate().selectedDate
        : null,
      course: !this.isOpenTicket ? this.formDataService.getCourseType().course : null,
    };

    //set active tab
    this.formDataService.setActiveStep("step_3", true);

    //get cart items
    this.cartItems = this.formDataService.getCart();

    //get api Data
    this.apiService
      .getTickets(this.apiData)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {

        //get tickets from API
        if (this.cartItems.length <= 0) {
          this.ticketsArrModified = this.formDataService.setTicketStructure(
            data,
            this.isOpenTicket
          );
        }
        //get tickets from prev stored data
        else {
          this.ticketsArrModified = this.formDataService.getTicketStructure();
        }

        this.isLoading = false;
        if(this.formDataService.getDiscountCode() != ""){
          this.discount = this.formDataService.getDiscountCode();
        }
        if(this.formDataService.getDiscountCodeString() != ""){
          this.discountResponse = this.formDataService.getDiscountCodeString();
        }
      });
  }

  decrementQty(ticket, type, ticketIndex, typeIndex, event) {
    this.discount = null;
    this.discountResponse = null;
    this.formDataService.setDiscountCodeString("");
    this.formDataService.setDiscountCode("");
    const ticketRef = this.ticketsArrModified[ticketIndex],
      typeRef = ticketRef.types[typeIndex];

    //get check if qty between range
    let currentQty = parseInt(typeRef.modelVal);
    if (currentQty <= 0) {
      return;
    }

    //update qty of type
    typeRef.modelVal = currentQty - 1;

    //decrement total qty of ticket
    let currentTicketQty = ticketRef.calcData.totalQty;
    ticketRef.calcData.totalQty = currentTicketQty - 1;

    //Decrement TOTAL Ticket
    let currentTotal = parseFloat(ticketRef.calcData.totalPrice);
    ticketRef.calcData.totalPrice = currentTotal - parseFloat(typeRef.price);
    ticketRef.calcData.discountTotalPrice = 0;

    //check ticket total qty status
    let ticketTotalQty = parseInt(ticketRef.calcData.totalQty);
    ticketRef.calcData.available = ticketTotalQty > 0;

    //decrement ticket
    let cartType = {
      id: type.id,
      name: type.name,
      price: type.price,
      modelName: "input__" + type.id,
      modelVal: typeRef.modelVal,
    };

    this.formDataService.decrementTicketFromCart(ticket.id, cartType);
  }

  incrementQty(ticket, type, ticketIndex, typeIndex, event) {
    this.discount = null;
    this.discountResponse = null;
    this.formDataService.setDiscountCodeString("");
    this.formDataService.setDiscountCode("");
    const ticketRef = this.ticketsArrModified[ticketIndex],
      typeRef = ticketRef.types[typeIndex];

    //check if the total qty is not extending the tickets available
    if (parseInt(ticketRef.calcData.totalQty) >= ticket.available_tickets) {
      return;
    }

    //get check if qty between range
    let currentQty = parseInt(typeRef.modelVal);
    if (currentQty >= ticket.available_tickets) {
      return;
    }

    //update qty
    typeRef.modelVal = currentQty + 1;

    //increment total qty of ticket
    let currentTicketQty = ticketRef.calcData.totalQty;
    ticketRef.calcData.totalQty = currentTicketQty + 1;

    //Increment TOTAL Ticket
    let currentTotal = parseFloat(ticketRef.calcData.totalPrice);
    ticketRef.calcData.totalPrice = currentTotal + parseFloat(typeRef.price);
    ticketRef.calcData.discountTotalPrice = 0;

    //check ticket total qty status
    let ticketTotalQty = parseInt(ticketRef.calcData.totalQty);

    ticketRef.calcData.available = ticketTotalQty > 0;

    //set / increment item to Cart[]
    let cartType = {
      id: type.id,
      name: type.name,
      price: type.price,
      modelName: "input__" + type.id,
      modelVal: typeRef.modelVal,
    };

    let cartTicket = {
      id: ticket.id,
      created_at: ticket.created_at,
      updated_at: ticket.updated_at,
      date: ticket.date,
      start_at: ticket.start_at,
      end_at: ticket.end_at,
      available_tickets: ticket.available_tickets,
      calcData: ticketRef.calcData,
      types: [cartType],
    };

    this.formDataService.incrementTicketToCart(cartTicket, cartType);
  }

  toggleElement(event) {
    let targetParent = event.currentTarget.parentElement;
    targetParent.classList.toggle("open");
    targetParent.children[1].classList.toggle("show");
  }

  checkInput(ele, ticketIndex, typeIndex) {
    let currentValue = parseInt(ele.value),
      modelValue = this.ticketsArrModified[ticketIndex].available_tickets;

    if (currentValue < 0 || currentValue > parseInt(modelValue)) {
      this.ticketsArrModified[ticketIndex].types[typeIndex].modelVal = 0;
    }
  }

  goToNext() {
    if (this.cartItems.length > 0) {
      this.router.navigate(["/payment"], {
        queryParamsHandling: "merge",
        queryParams: { discount_code: this.discount },
      });
    }
  }

  onInputDiscountChange(ticket: any){
    this.discountError = false;
    if(this.discount == ''){
     this.applyDiscount(ticket);
    }
  }

  applyDiscount(ticket: any) {
    this.discountResponse = "";
    this.discountError = false;
    this.formDataService.setDiscountCodeString("");
    if (this.cartItems[0] && this.cartItems[0].types) {
      let tickets = [];
      if(this.isOpenTicket){
        this.cartItems.forEach((item) => {
          let element = item.types[0];
          if(item.types[0].price != 0){
            tickets.push({ id: element.id, quantity: element.modelVal });
          }
        });
      }else{
        this.cartItems[0].types.forEach((element) => {
          tickets.push({ id: element.id, quantity: element.modelVal });
        });
      }
      let info = {
        location_permalink: this.locationPermalink,
        tickets,
      };
      if(this.discount != ""){
        info["discount_code"] = this.discount;
      }

      this.apiService
        .applyDiscount(info)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (data) => {
            this.ticketsArrModified[
              this.ticketsArrModified.findIndex((el) => el.id === ticket.id)
            ].calcData.discountTotalPrice = data.total_amount;
            if(data.discount > 0){
              this.discountResponse = `Sconto di ${Math.round(data.discount * 100) / 100} €`;
            }
           this.formDataService.setDiscountCodeString(this.discountResponse);
           this.formDataService.setDiscountCode(this.discount);
          },
          (error: any) => {
            this.discountError = true;
            this.discountResponse = error.error.error;
          }
        );
    }
  }

  handleDisabledTicket(ticket){
    let current_date = this.getCurrentDate();
    let current_hour = this.getCurrentHour();
    if(  ticket.available_tickets <= 0 || (ticket.date == current_date && ticket.start_at < current_hour)){
      return true;
    }
    return false;
  }

  getCurrentDate(){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  }

  getCurrentHour(){
    var today = new Date();
    var hour = today.getHours();
    var time = ("0" + hour).slice(-2) + ":" + today.getMinutes();
    return time;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
