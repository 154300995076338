import {Component, OnInit} from '@angular/core';
import {FormDataService} from "../data/form-data.service";

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  myOrders: any;

  constructor(
    private formDataService: FormDataService,
  ) {
    this.myOrders = this.formDataService.getOrderedItems();
  }

  ngOnInit() {
    //reset all form data
    this.formDataService.resetFormData();

    //reset active steps
    this.formDataService.resetActiveSteps();
  }

  printTickets(url: string) {
    window.open(url, "_blank");
  }

  ngOnDestroy() {
    this.formDataService.resetCart();
    this.formDataService.resetPersonal();
    this.formDataService.setOrderedItems(null);
  }

}
