import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormDataService } from "../data/form-data.service";
import { SharedDataService } from "../config/shared-data.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.scss"],
})
export class LocationsComponent implements OnInit, OnDestroy {
  locations: Array<any> = [];
  selectedLocation: string;
  isOpenTicket: boolean = false;

  locationInfo: any = {};

  isLoading: boolean = true;

  hasError: boolean = false;

  selectedOptionIndex: any = null;
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formDataService: FormDataService,
    private sharedDataService: SharedDataService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        this.isOpenTicket = !!params.open_ticket;
        if (params.location) this.selectedLocation = params.location;
      });

    //no location provided
    if (
      this.selectedLocation !== undefined ||
      (this.selectedLocation && this.selectedLocation === "")
    ) {
      let locationData = {
        permalink: this.selectedLocation,
      };

      this.formDataService.setLocationUrl(locationData);
      if (this.isOpenTicket) {
        this.formDataService.activeSteps["step_3"] = true;
        this.router.navigate(["/tickets"], { queryParamsHandling: "merge" });
      } else {
        this.formDataService.activeSteps["step_1"] = true;
        this.router.navigate(["/calendar"], { queryParamsHandling: "merge" });
      }
      return;
    }

    this.formDataService.resetActiveSteps();
  }

  ngOnInit() {
    this.locationInfo = 0; //set disabled default

    this.sharedDataService
      .getLocations()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (!res.success) {
          this.isLoading = false;
          this.hasError = true;
          return;
        }

        this.locations = res.data.locations;
        this.isLoading = false;
      });
  }

  locationChange(ev) {
    this.selectedOptionIndex = ev.target.selectedIndex - 1;
  }

  addLocation(form) {
    if (!form.valid) {
      this.hasError = true;
      return;
    }

    if (this.selectedOptionIndex === null) {
      this.hasError = true;
      return;
    }

    let location = this.locations[this.selectedOptionIndex];

    let locationData = {
      id: location.id,
      permalink: location.permalink,
      name: location.name,
    };

    this.formDataService.setLocationUrl(locationData);
    this.sharedDataService.changeMessage(locationData.name);
    this.formDataService.activeSteps["step_1"] = true;
    this.router.navigate(["/calendar"], { queryParamsHandling: "merge" });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
