import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from "rxjs";
import { CONFIG } from "./config";
import { catchError, map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgxSmartModalService } from "ngx-smart-modal";

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private messageSource = new BehaviorSubject('');

  currentMessage = this.messageSource.asObservable();


  private modalObj: Object = {
    title: 'Si è verificato un errore!',
    icon: 'sad.png', // => assets/img/icons/ {name}
    btnText: 'Indietro',
    btnRedirect: '/'
  };

  location: Object = {
    id: null,
    permalink: null,
    name: null
  };

  private headers: HttpHeaders;

  constructor(private http: HttpClient,
    public ngxSmartModalService: NgxSmartModalService,) {
      this.headers = new HttpHeaders().set("Content-Type", "application/json");
  }

  changeMessage(data: string) {
    this.messageSource.next(data)
  }


  getLocations(): Observable<any> {

    return this.http.get(CONFIG.API_URL + 'locations?online=true', { headers: this.headers }).pipe(
      map((res: any) => {
        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {

        if (error.error.errors) {
          this.modalObj['title'] = error.error.errors[0];
        }

        this.ngxSmartModalService.setModalData(this.modalObj, 'kalataModalInfo', true);

        this.ngxSmartModalService.open('kalataModalInfo');

        return of(result as T);
      })
    );
  }
}
